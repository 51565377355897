<template>
<div>
    <h4 class="title is-4 is-marginless">{{ widget.title }}</h4>
    <div v-if="widget.intro" v-html="parsedIntro"></div>
    <div v-show="loaded" id="customer-checklists-line-chart">
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</div>
</template>
<script>
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import moment from 'moment'
import ReportUtils from '../../utils'

export default {

    props: {
        rows: {
            type: Array,
            default: () => ([])
        },
        widget: {
            type: Object,
            default: () => ({})
        },
        date: String,
        dateKey: String,
    },

    data: () => ({
        loaded: false
    }),

    mounted() {
        this.$nextTick(() => {
            this.loaded = true
        })
    },

    methods: {
        getDataForColumn(column) {
            return map(this.groupedRows, (group, date) => {
                if(column.key) {
                    const items = group.filter(row => {
                        return row[column.key] === column.value && moment(row[this.dateKey]).startOf('day').format('Y-M-DD') === date
                    })

                    return items.length
                }
                return group.length
            })
        }
    },

    computed: {
        chartOptions() {
            return {
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                },
                colors: this.widget.columns.map(column => column.colour),
                xaxis: {
                    categories: this.chartCategories,
                    labels: {
                        style: {
                            colors: '#ffffff'
                        }
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#ffffff'
                        }
                    }
                },
                tooltip: {
                    theme: 'dark',
                }
            }
        },
        series() {
            return this.widget.columns.map(column => {
                return {
                    name: column.label,
                    data: this.getDataForColumn(column)
                }
            })
        },
        groupedRows() {
            return groupBy(
                sortBy(this.rows, this.dateKey), 
                row => moment(row[this.dateKey]).startOf('day').format('Y-M-DD')
            )
        },
        parsedIntro() {
            return ReportUtils['parse_keys'](this.widget.intro, this.rows, this.date)
        },
        chartCategories() {
            return Object.keys(this.groupedRows)
        }
    }

}
</script>